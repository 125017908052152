<template>
  <div class="warp">
    <div class="header">
      <img src="../assets/header.png" alt="" />
    </div>
    <div class="cover">
      <img :src="img" alt="" />
    </div>
    <div class="title">{{ title }}</div>
    <audio :src="mp3" ref="audioUrlRefText" @timeupdate="updateTime"></audio>
    <div class="progress">
      <div class="left">
        <div>
          <img
            src="../assets/pre.png"
            alt=""
            @click="preAudio"
            v-if="num != 0"
          />
        </div>
        <div>
          <img
            src="../assets/player.png"
            alt=""
            @click="playAudio"
            v-if="!isPlay"
          />
          <img src="../assets/stop.png" alt="" @click="stopAudio" v-else />
        </div>
        <div>
          <img
            src="../assets/next.png"
            alt=""
            @click="nextAudio"
            v-if="num != audioArr.length - 1"
          />
        </div>
      </div>
      <div class="center">
        <div :style="{ width: width + 'rem' }"></div>
      </div>
      <div class="right">{{ currentTime }} / {{ endTimer }}</div>
    </div>
    <div class="more">
      <div class=" " @click="back()">听更多红音</div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {},
  // 定义属性
  data() {
    return {
      list: [
        {
          book: require("@/assets/保卫延安.jpg"),
          name: "保卫延安",
          audioArr: [
            "https://qiniu-cjyz.ninewe.com/2022%E5%8D%97%E6%98%8C%E5%A5%BD%E5%A3%B0%E9%9F%B3/%E4%BF%9D%E5%8D%AB%E5%BB%B6%E5%AE%89%20%28mp3cut.net%29.mp3"
          ],
          time: 1604.623673
        },
        {
          book: require("@/assets/红岩.jpg"),
          name: "红岩",
          audioArr: [
            "https://qiniu-cjyz.ninewe.com/2022%E5%8D%97%E6%98%8C%E5%A5%BD%E5%A3%B0%E9%9F%B3/%E7%BA%A2%E5%B2%A9.mp3"
          ],
          time: 1333.106939
        },
        {
          book: require("@/assets/黄河东流去.jpg"),
          name: "黄河东流去",
          audioArr: [
            "https://qiniu-cjyz.ninewe.com/2022%E5%8D%97%E6%98%8C%E5%A5%BD%E5%A3%B0%E9%9F%B3/%E9%BB%84%E6%B2%B3%E4%B8%9C%E6%B5%81%E5%8E%BB%20%28mp3cut.net%29.mp3"
          ],
          time: 1290.396735
        },
        {
          book: require("@/assets/党旗下的誓言.png"),
          name: "党旗下的誓言",
          audioArr: [
            "https://qiniu-cjyz.ninewe.com/2022%E5%8D%97%E6%98%8C%E5%A5%BD%E5%A3%B0%E9%9F%B3/%E5%85%9A%E6%97%97%E4%B8%8B%E7%9A%84%E8%AA%93%E8%A8%80.mp3"
          ],
          time: 256.888163
        },
        {
          book: require("@/assets/大中国.png"),
          name: "大中国",
          audioArr: [
            "https://qiniu-cjyz.ninewe.com/2022%E5%8D%97%E6%98%8C%E5%A5%BD%E5%A3%B0%E9%9F%B3/%E5%A4%A7%E4%B8%AD%E5%9B%BD.mp3"
          ],
          time: 224.705306
        },
        {
          book: require("@/assets/东方红.png"),
          name: "东方红",
          audioArr: [
            "https://qiniu-cjyz.ninewe.com/2022%E5%8D%97%E6%98%8C%E5%A5%BD%E5%A3%B0%E9%9F%B3/%E4%B8%9C%E6%96%B9%E7%BA%A2.mp3"
          ],
          time: 189.100408
        },
        {
          book: require("@/assets/歌唱祖国.png"),
          name: "歌唱祖国",
          audioArr: [
            "https://qiniu-cjyz.ninewe.com/2022%E5%8D%97%E6%98%8C%E5%A5%BD%E5%A3%B0%E9%9F%B3/%E6%AD%8C%E5%94%B1%E7%A5%96%E5%9B%BD.mp3"
          ],
          time: 183.066122
        },
        {
          book: require("@/assets/解放战争.jpg"),
          name: "解放战争",
          audioArr: [
            "https://qiniu-cjyz.ninewe.com/2022%E5%8D%97%E6%98%8C%E5%A5%BD%E5%A3%B0%E9%9F%B3/%E8%A7%A3%E6%94%BE%E6%88%98%E4%BA%89%20%28mp3cut.net%29.mp3"
          ],
          time: 534.073469
        },
        {
          book: require("@/assets/守住底线--69封廉政家书.png"),
          name: "守住底线--69封廉政家书",
          audioArr: [
            "https://qiniu-cjyz.ninewe.com/2022%E5%8D%97%E6%98%8C%E5%A5%BD%E5%A3%B0%E9%9F%B3/69%E5%B0%81%E5%BB%89%E6%94%BF%E5%AE%B6%E4%B9%A6%20%28mp3cut.net%29.mp3"
          ],
          time: 188.238367
        },
        {
          book: require("@/assets/微党课--政治反腐.jpg"),
          name: "微党课--政治反腐",
          audioArr: [
            "https://qiniu-cjyz.ninewe.com/2022%E5%8D%97%E6%98%8C%E5%A5%BD%E5%A3%B0%E9%9F%B3/%E5%BE%AE%E5%85%9A%E8%AF%BE-%E6%94%BF%E6%B2%BB%E5%8F%8D%E8%85%90%20%28mp3cut.net%29.mp3"
          ],
          time: 375.353469
        }
      ],
      time: "",
      title: "", //标题
      img: "", //图片
      str: null,
      isPlay: false, //是否播放
      mp3: "", //当前播放的mp3
      num: 0, //第几首
      audioArr: [], //所有的语音
      au: "",
      currentTime: "00:00", //当前播放的时长
      endTimer: "00:00", //结束时间

      currentSeconds: "50", //当前多少秒
      endSeconds: "", //结束时间是多少秒
      width1: "",
      width: ""
    };
  },
  created() {
    this.str = this.$route.query.str;
    this.list.map(el => {
      if (el.name === this.str) {
        this.audioArr = el.audioArr;
        this.title = el.name;
        this.img = el.book;
        this.time = el.time;
      }
    });
    this.mp3 = this.audioArr[this.num];
  },
  mounted() {
    this.endSeconds = this.time;
    this.endTimer = this.timeInit(this.time);
  },
  computed: {},
  watch: {},
  methods: {
    timeInit(time) {
      //分钟
      var minute = time / 60;
      var minutes = parseInt(minute);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      //秒
      var second = time % 60;
      var seconds = Math.round(second);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return minutes + ":" + seconds;
    },
    init() {
      if (this.$refs.audioUrlRefText) {
        let time = this.$refs.audioUrlRefText.duration;
        this.endSeconds = time;
        this.endTimer = this.timeInit(time);
      }
    },
    back() {
      this.$router.go(-1);
    },
    playAudio() {
      this.isPlay = true;
      this.$refs.audioUrlRefText.play();
    },
    stopAudio() {
      this.isPlay = false;
      this.$refs.audioUrlRefText.pause();
    },
    updateTime(e) {
      this.currentTime = this.timeInit(e.target.currentTime); //获取audio当前播放时间
      this.currentSeconds = e.target.currentTime;
      this.width1 = 4.5 / this.endSeconds;
      this.width = +this.currentSeconds * +this.width1;
    },
    preAudio() {
      if (this.num > 0) {
        this.num--;
        this.mp3 = this.audioArr[this.num];
        this.isPlay = true;
        setTimeout(() => {
          this.init();
          this.$refs.audioUrlRefText.play();
        }, 1000);
      }
    },
    nextAudio() {
      if (this.num != this.audioArr.length - 1) this.num++;
      this.mp3 = this.audioArr[this.num];
      this.isPlay = true;
      setTimeout(() => {
        this.init();
        this.$refs.audioUrlRefText.play();
      }, 1000);
    }
  },

  // 生命周期 - 创建之前
  beforeCreate() {},
  // 生命周期 - 挂载之前
  beforeMount() {},
  // 生命周期 - 更新之前
  beforeUpdate() {},
  // 生命周期 - 更新之后
  updated() {},
  // 生命周期 - 销毁之前
  beforeDestroy() {},
  // 生命周期 - 销毁完成
  destroyed() {},
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {}
};
</script>

<style lang="scss" scoped>
.warp {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/bg1.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .header {
    text-align: center;
    padding: 50px;
    img {
      width: 3.5rem;
    }
  }
  .cover {
    text-align: center;
    img {
      width: 300px;
    }
  }
  .title {
    text-align: center;
    font-size: 0.5rem;
    color: white;
    padding: 0.7rem 0 0 0;
  }
  .progress {
    width: 100vw;
    height: 0.96rem;
    background-color: hsla(0, 0%, 93.7%, 0.625);
    margin: 20px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .left {
      height: 0.96rem;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        width: 0.7rem;
        img {
          width: 0.7rem;
        }
      }
    }
    .center {
      width: 4.5rem;
      height: 20px;
      background: #ddd;
      position: relative;
      div {
        position: absolute;
        left: 0;
        top: 0;
        height: 20px;
        background: #fc561e;
      }
    }
    .right {
      width: 2.3rem;
    }
  }
  .more {
    display: flex;
    justify-content: center;
    div {
      padding: 0.1rem 0.2rem;
      border: 2px solid white;
      color: white;
      font-size: 0.3rem;
    }
  }
}
</style>
